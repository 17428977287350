import React, { useState } from 'react'
import { Button } from '@chakra-ui/react';
import GenericTable from '../GenericTable'
import apiRouteConstants from 'constants/apiRouteConstants'
import CampaignColumns from './CampaignColumns'
import GenericModalForm from 'components/form/GenericForm';
import * as Yup from "yup"
import { post } from 'utils/apiClient';
import InputField from 'components/fields/InputField';

const formFields = [
    {
        name: 'camp_name',
        label: 'Campaign Name',
        type: 'text',
        placeholder: 'Enter campaign name',
        isRequired: true,
        validation: Yup.string()
            .required('Campaign name is required'),
    },
    {
        name: 'description',
        label: 'Description',
        type: 'text',
        placeholder: 'Enter description',
        isRequired: true,
        validation: Yup.string()
            .required('Description is required'),
    },
    {
        name: 'camp_mode',
        label: 'Strategy',
        type: 'select',
        placeholder: 'Select Strategy',
        isRequired: true,
        options: [
            { label: "Priority", value: 1 },
            { label: "Weight", value: 2 }
        ],
        validation: Yup.string()
            .required('Strategy is required'),
    },
    // {
    //     name: 'call_timeout',
    //     label: 'Call Timeout',
    //     type: 'number',
    //     placeholder: 'Enter Call Timeout',
    //     isRequired: true,
    //     validation: Yup.string()
    //         .required('Call Timeout is required'),
    // },
    // {
    //     name: 'ring_timeout',
    //     label: 'Ring Timeout',
    //     type: 'number',
    //     placeholder: 'Enter Ring Timeout',
    //     isRequired: true,
    //     validation: Yup.string()
    //         .required('Ring Timeout is required'),
    // },
    {
        name: 'active',
        label: 'Active',
        type: 'checkbox',
    },
]

const CampaignTable = () => {
    const [fetchKey, setFetchKey] = useState(0);
    const [filters, setFilters] = useState({ search: "" })
    const [isModalOpen, setModalOpen] = useState(false)
    const [initialValues, setInitialValues] = useState({})

    const FilterComponents = () => (
        <div>
            <InputField
                value={filters.search}
                placeholder="Search campaign"
                label="Search"
                onChange={(e) => {
                    setFilters({
                        ...filters,
                        search: e.target.value,
                    });
                }}
            />
        </div>
    );

    const createButton = () => (
        <Button
            onClick={() => setModalOpen(true)}
            className='dark:!bg-white/5 dark:border rounded-xl border bg-white/0 p-3 text-sm border-gray-200 dark:!border-white/10 dark:text-white'
        >
            Create
        </Button>
    )

    const handleSubmit = async (values) => {
        // Implement form submission logic here
        try {
            values["active"] = values.active ? 1 : 0
            await post(apiRouteConstants.campaign.create, values)
            setModalOpen(false);
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);

        }
    };

    const handleUpdate = async (values) => {
        // Implement form submission logic here
        try {
            const id = initialValues.id
            values["active"] = values.active ? 1 : 0
            delete values["id"]
            await post(`${apiRouteConstants.campaign.edit}/${id}`, values)
            setModalOpen(false);
            setInitialValues({})
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);

        }
    };

    const handlePartialUpdate = async (campaignId, data) => {
        try {
            await post(`${apiRouteConstants.campaign.edit}/${campaignId}`, data)
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    }

    const handleEdit = (data) => {
        setInitialValues(data)
        setModalOpen(true);
    }

    const handleDelete = async (data) => {
        try {
            await post(apiRouteConstants.campaign.delete, data)
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='relative'>
            <div>
                <GenericTable
                    key={fetchKey}
                    createButton={createButton}
                    columns={CampaignColumns({ handleEdit, handleDelete, handlePartialUpdate })}
                    fetchUrl={apiRouteConstants.campaign.list}
                    title="Campaigns"
                    filterComponents={FilterComponents}
                    filterValues={filters} />
            </div>
            <GenericModalForm
                isOpen={isModalOpen}
                onClose={() => { setModalOpen(false); setInitialValues({}) }}
                title="Campaign Form"
                formFields={formFields}
                initialValues={initialValues} // Pass initialValues only if editing
                onSubmit={Object.keys(initialValues).length > 0 ? handleUpdate : handleSubmit}
            />
        </div>
    )
}

export default CampaignTable