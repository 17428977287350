import React, { useState } from 'react'
import { Button } from '@chakra-ui/react';
import GenericTable from '../GenericTable'
import apiRouteConstants from 'constants/apiRouteConstants'
import TargetColumns from './TargetColumns'
import GenericModalForm from 'components/form/GenericForm';
import * as Yup from "yup"
import { post } from 'utils/apiClient';
import InputField from 'components/fields/InputField';
import { useParams } from 'react-router-dom';
import SelectField from 'components/fields/SelectField';

const formFields = [
    {
        name: 'targetName',
        label: 'Name',
        type: 'text',
        placeholder: 'Enter name',
        isRequired: true,
        validation: Yup.string()
            .required('Name is required'),
    },
    {
        name: 'targetNumber',
        label: 'Number',
        type: 'number',
        placeholder: 'Enter number',
        isRequired: true,
        validation: Yup.string('Only numbers are allowed')
            .matches(/^(1888|1877|1866|1855|1844|1833|1800)\d{7}$/, 'Invalid TFN')
            .required('TFN is required'),
    },
    {
        name: 'description',
        label: 'Description',
        type: 'text',
        placeholder: 'Enter description',
        isRequired: true,
        validation: Yup.string()
            .required('Description is required'),
    },
    {
        name: 'dailyCap',
        label: 'Daily Cap',
        type: 'number',
        placeholder: 'Enter Daily Cap',
        isRequired: true,
        validation: Yup.number("Only numbers are allowed")
            .typeError('Must be a valid number')
            .integer('Daily cap must be an integer')
            .min(0, 'Daily cap must be at least 0')
            .max(1000, 'Daily cap must be at most 1000')
            .required('Daily cap is required'),
    },
    {
        name: 'monthlyCap',
        label: 'Monthly Cap',
        type: 'number',
        placeholder: 'Enter Monthly Cap',
        isRequired: true,
        validation: Yup.number("Only numbers are allowed")
            .typeError('Must be a valid number')
            .integer('Monthly Cap must be an integer')
            .min(0, 'Monthly Cap must be at least 0')
            .max(1000, 'Monthly Cap must be at most 1000')
            .required('Monthly Cap is required'),
    },
    {
        name: 'concurrentCalls',
        label: 'Concurrent calls',
        type: 'number',
        placeholder: 'Enter Concurrent calls',
        isRequired: true,
        validation: Yup.number("Only numbers are allowed")
            .typeError('Must be a valid number')
            .integer('Concurrent calls must be an integer')
            .min(0, 'Concurrent calls must be at least 0')
            .max(1000, 'Concurrent calls must be at most 1000')
            .required('Concurrent calls is required'),
    },
    {
        name: 'camp_mode',
        label: 'Priority / Weight',
        type: 'number',
        placeholder: 'Enter Priority / Weight',
        isRequired: true,
        validation: Yup.number("Only numbers are allowed")
            .required('Priority / Weight is required'),
    },
    {
        name: 'ringTimeout',
        label: 'Ring Timeout (In Seconds)',
        type: 'number',
        placeholder: 'Enter Ring Timeout',
        isRequired: true,
        validation: Yup.number("Only numbers are allowed")
            .required('Ring Timeout is required'),
    },
    {
        name: 'callTimeout',
        label: 'Max Duration (In Seconds)',
        type: 'number',
        placeholder: 'Enter Max Duration',
        isRequired: true,
        validation: Yup.number("Only numbers are allowed")
            .required('Max Duration is required'),
    },
    {
        name: 'active',
        label: 'Status',
        type: 'checkbox',
    },
]

const TargetTable = () => {
    const { campaignId } = useParams()

    const [fetchKey, setFetchKey] = useState(0);
    const [filters, setFilters] = useState({ search: "", campaignId })
    const [isModalOpen, setModalOpen] = useState(false)

    const FilterComponents = () => (
        <div className='w-full md:w-auto flex flex-col md:flex-row gap-4'>
            <SelectField
                value={filters.active}
                label="Active"
                flex="1"
                options={[{ label: "Active", value: 1 }, { label: "Inactive", value: 0 }]}
                placeholder="All"
                onChange={(e) => {
                    if (e.target.value) {
                        setFilters({
                            ...filters,
                            active: Number(e.target.value),
                        });
                    } else {
                        setFilters({ ...filters, active: "" });
                    }
                }}
            />
            <InputField
                value={filters.search}
                placeholder="Search Targets"
                label="Search"
                onChange={(e) => {
                    setFilters({
                        ...filters,
                        search: e.target.value,
                    });
                }}
            />
        </div>
    );

    const createButton = () => (
        <Button
            onClick={() => setModalOpen(true)}
            className='dark:!bg-white/5 dark:border rounded-xl border bg-white/0 p-3 text-sm border-gray-200 dark:!border-white/10 dark:text-white'
        >
            Create
        </Button>
    )

    const handleSubmit = async (values) => {
        // Implement form submission logic here
        try {
            console.log(values);
            const payload = {
                ...values,
                campaignId,
                active: values.active ? 1 : 0,
                concurrentCalls: Number(values.concurrentCalls) === 0 ? -1 : values.concurrentCalls,
                dailyCap: Number(values.dailyCap) === 0 ? -1 : values.dailyCap,
                monthlyCap: Number(values.monthlyCap) === 0 ? -1 : values.monthlyCap,
            }
            await post(apiRouteConstants.target.create, payload)
            setModalOpen(false);
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    };

    const handlePartialUpdate = async (targetId, data) => {
        try {
            await post(`${apiRouteConstants.target.edit}/${targetId}`, data)
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    }

    const handleDelete = async (targetId) => {
        try {
            await post(`${apiRouteConstants.target.delete}`, { targetId })
            setFetchKey(prevKey => prevKey + 1);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div>
            <div>
                <GenericTable
                    key={fetchKey}
                    createButton={createButton}
                    columns={TargetColumns({ handleDelete, handlePartialUpdate })}
                    fetchUrl={apiRouteConstants.target.list}
                    filterComponents={FilterComponents}
                    filterValues={filters} />
            </div>
            <GenericModalForm
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                title="Target Form"
                formFields={formFields}
                initialValues={{
                    dailyCap: 0,
                    monthlyCap: 0,
                    camp_mode: 1,
                    ringTimeout: 20,
                    callTimeout: 3600,
                }} // Pass initialValues only if editing
                onSubmit={handleSubmit}
            />
        </div>
    )
}

export default TargetTable