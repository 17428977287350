import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";
import { Modal, ModalOverlay, ModalHeader, ModalContent, ModalCloseButton, ModalBody } from "@chakra-ui/modal";

export default function Admin(props) {
  const { ...rest } = props;
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [currentRoute, setCurrentRoute] = React.useState("Main Dashboard");
  const [isOpen, setIsOpen] = React.useState(true);

  React.useEffect(() => {
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
  }, []);
  React.useEffect(() => {
    getActiveRoute(routes);

  }, [location.pathname]);

  const getActiveRoute = (routes) => {
    let activeRoute = "Main Dashboard";
    const currentPath = window.location.pathname;
    for (let i = 0; i < routes.length; i++) {
      const routePath = routes[i].layout + "/" + routes[i].path;
      const routeParts = routePath.split("/").filter(Boolean);
      const currentPathParts = currentPath.split("/").filter(Boolean);
      let isMatch = true;
      for (let j = 0; j < routeParts.length; j++) {
        if (routeParts[j].startsWith(":")) {
          continue;
        }
        if (routeParts[j] !== currentPathParts[j]) {
          isMatch = false;
          break;
        }
      }
      if (isMatch && routeParts.length === currentPathParts.length) {
        activeRoute = routes[i].name;
        setCurrentRoute(activeRoute);
        break;
      }
    }
    return activeRoute;
  };
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (
        window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
      ) {
        return routes[i].secondary;
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };

  document.documentElement.dir = "ltr";
  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* Navbar & Main Content */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        {/* Main Content */}
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[220px]`}
        >
          {/* Routes */}
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              logoText={"Horizon UI Tailwind React"}
              brandText={currentRoute}
              secondary={getActiveNavbar(routes)}
              {...rest}
            />
            <div className="pt-5s mx-auto mb-auto h-full min-h-[84vh] p-2 md:pr-2">
              <Routes>
                {getRoutes(routes)}

                <Route
                  path="/"
                  element={<Navigate to="/admin/default" replace />}
                />
              </Routes>
            </div>
            <div className="p-3">
              <Footer />
            </div>
          </div>
        </main>
        <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size={'xl'}>
          <ModalOverlay />
          <ModalContent
            className='dark:!bg-navy-800 dark:!text-white !rounded-xl'
          >
            <ModalHeader>Changelog 1.0</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <h1
                className="text-2xl font-semibold text-navy-700 dark:text-white mb-5"
              >Targets Module</h1>
              <ul
                className="text-sm font-semibold text-navy-700 dark:text-white list-decimal list-inside ml-4"
              >
                <li
                  className="text-sm font-semibold text-navy-700 dark:text-white mb-2"
                >
                  Enabled Ring Timeout
                  <ul
                    className="text-sm font-semibold text-navy-700 dark:text-white list-disc list-inside ml-4"
                  >
                    <li>Ring Timeout is the time limit for the call to Ring. If the call is not Answered within the specified time, the call will be disconnected on that target and sent to the next target in the campaign.</li>
                    <li>Default value for Ring Timeout is 60 seconds.</li>
                    <li>You can change these values as per your requirement.</li>
                  </ul>
                </li>

                <li
                  className="text-sm font-semibold text-navy-700 dark:text-white mb-2"
                >
                  Enabled Max Duration
                  <ul
                    className="text-sm font-semibold text-navy-700 dark:text-white list-disc list-inside ml-4"
                  >
                    <li>Max Duration is the time limit for the call to be running. If the call connected and active till the specified time, the call will be disconnected automatically.</li>
                    <li>Default value for Max Duration is 3600 seconds.</li>
                    <li>You can change these values as per your requirement.</li>
                  </ul>
                </li>
              </ul>
            </ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </div>
  );
}
