import { createColumnHelper } from "@tanstack/react-table";
import Switch from "components/switch";
import TableHeader from "components/tableCell/TableHeader";
import React from 'react';
import { Link, useNavigate } from "react-router-dom";

const columnHelper = createColumnHelper();

const CampaignColumns = ({ handleEdit, handleDelete, handlePartialUpdate }) => {
    const navigate = useNavigate()

    return [
        columnHelper.accessor("camp_name", {
            id: "camp_name",
            header: () => <TableHeader title="Name" className="text-center" />,
            cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">
                <Link to={`${info.row.original.id}/targets`} className="hover:underline">
                    {info.getValue() || ''}
                </Link>
            </p>,
        }),
        columnHelper.accessor("description", {
            id: "description",
            header: () => <TableHeader title="Description" className="text-center" />,
            cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
        }),
        //columnHelper.accessor("call_timeout", {
        //    id: "call_timeout",
        //    header: () => <TableHeader title="Call Timeout" className="text-center" />,
        //    cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
        //}),
        //columnHelper.accessor("ring_timeout", {
        //    id: "ring_timeout",
        //    header: () => <TableHeader title="Ring Timeout" className="text-center" />,
        //    cell: (info) => <p className="text-xs text-center font-bold text-navy-700 dark:text-white">{info.getValue() || ''}</p>,
        //}),
        // other columns...
        columnHelper.accessor("active", {
            id: "active",
            header: () => <TableHeader title="Status" className="text-center" />,
            cell: (info) => (
                <div className="flex items-center justify-center">
                    <Switch
                        width="w-[108px]"
                        checked={Boolean(info.getValue())}
                        onChange={() => handlePartialUpdate(info.row.original.id, { active: Number(info.getValue()) === 1 ? 0 : 1 })}
                    />
                </div>
            ),
        }),
        columnHelper.accessor("actions", {
            id: "actions",
            header: () => <TableHeader title="Actions" className="text-center" />,
            cell: (info) => (
                <div className="flex items-center gap-2 justify-center">
                    <button
                        className="text-xs text-white bg-indigo-700 dark:bg-indigo-500 px-2 py-1 rounded disabled:bg-gray-600"
                        onClick={() => navigate(`${info.row.original.id}/targets`)}
                    >
                        View Targets
                    </button>
                    <button
                        className="text-xs text-white bg-yellow-700 dark:bg-yellow-500 px-2 py-1 rounded disabled:bg-gray-600"
                        onClick={() => handleEdit(info.row.original)}
                    >
                        Edit
                    </button>
                    <button
                        className="text-xs text-white bg-red-700 dark:bg-red-500 px-2 py-1 rounded disabled:bg-gray-600"
                        onClick={() => handleDelete({ campaignId: info.row.original.id })}
                    >
                        Delete
                    </button>
                </div>
            ),
        }),
    ]
};

export default CampaignColumns;
