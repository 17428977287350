import React, { useEffect } from "react";
import Dropdown from "components/dropdown";
import { FiAlignJustify } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import { RiMoonFill, RiSunFill } from "react-icons/ri";
import { get } from "utils/apiClient";
import apiRouteConstants from "constants/apiRouteConstants";
import { Avatar } from "@chakra-ui/react";
import * as Yup from "yup"
import GenericModalForm from "components/form/GenericForm";
import { post } from "utils/apiClient";
import { MdTimer } from "react-icons/md";


const formFields = [
    {
        name: 'currentPassword',
        label: 'Current Password',
        type: 'text',
        placeholder: 'Enter Current Password',
        isRequired: true,
        validation: Yup.string()
            .required('Current Password is required'),
    },
    {
        name: 'newPassword',
        label: 'New Password',
        type: 'text',
        placeholder: 'Enter New Password',
        isRequired: true,
        validation: Yup.string()
            .required('New Password is required'),
    },
    {
        name: 'confirmPassword',
        label: 'Confirm Password',
        type: 'text',
        placeholder: 'Confirm Password',
        isRequired: true,
        validation: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    },
]

const useCESTTime = () => {
    const [time, setTime] = React.useState(new Date());

    useEffect(() => {
        const updateCESTTime = () => {
            setTime(new Date());
        };

        updateCESTTime();
        const interval = setInterval(updateCESTTime, 1000); // Update every second

        return () => clearInterval(interval); // Clear interval on unmount
    }, []);

    return time;
};

const Navbar = (props) => {
    const { onOpenSidenav, brandText } = props;
    const [darkmode, setDarkmode] = React.useState(false);
    const [isModalOpen, setModalOpen] = React.useState(false)
    const [userDetails, setUserDetails] = React.useState({
        email: "...",
        number: "...",
        balance: "...",
        full_name: "..."
    });
    const [callStats, setCallStats] = React.useState({
        answered_calls: "...",
        answered_live_calls: "...",
        live_calls: "...",
        missed_calls: "..."
    });
    const cestTime = useCESTTime();

    const navigate = useNavigate()

    const handleLogout = () => {
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        navigate("/auth/sign-in")
    }

    const fetchCallStats = async () => {
        try {
            const res = await get(apiRouteConstants.dashboard.callStats)
            if (res.data) {
                setCallStats(res.data)
            }
        } catch (err) {
            console.log(err);
        }
    }
    const fetchUserDetails = async () => {
        try {
            const res = await get(apiRouteConstants.userDetail)
            if (res.data) {
                setUserDetails({ ...res.data, full_name: `${res.data.first_name} ${res.data.last_name}` })
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleSubmit = async (data) => {
        try {
            const res = await post(apiRouteConstants.changePassword, data)
            if (res.status === 200) {
                handleLogout()
            }
        } catch (err) {
            console.log(err);
        }

    }

    useEffect(() => {
        const storedTheme = localStorage.getItem("theme");
        if (storedTheme === "dark") {
            document.body.classList.add("dark");
            setDarkmode(true);
        } else {
            document.body.classList.remove("dark");
            setDarkmode(false);
        }

        fetchUserDetails()
        fetchCallStats()
    }, [])

    return (
        <>
            <nav className="sticky top-4 z-40 flex flex-row flex-wrap items-center justify-between rounded-xl bg-white/10 p-2 backdrop-blur-xl dark:bg-[#0b14374d]">
                <div className="ml-[6px]">
                    {/* <div className="h-6 w-[224px] pt-1">
                    <span
                        className="text-sm font-normal text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                    >
                        Pages
                        <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
                            {" "}
                            /{" "}
                        </span>
                    </span>
                    <Link
                        className="text-sm font-normal capitalize text-navy-700 hover:underline dark:text-white dark:hover:text-white"
                        to="#"
                    >
                        {brandText}
                    </Link>
                </div> */}
                    <p className="shrink text-[33px] capitalize text-navy-700 dark:text-white">
                        <Link
                            to="#"
                            className="font-bold capitalize hover:text-navy-700 dark:hover:text-white"
                        >
                            {brandText}
                        </Link>
                    </p>
                </div>

                <div className="relative mt-[3px] flex h-[61px] max-w-none md:max-w-[355px] flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:gap-1 xl:gap-2">
                    <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                        Answered: {callStats.answered_calls}
                    </div>
                    {/* <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                    Answered Live Calls : {callStats.answered_live_calls}
                </div> */}
                    <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                        Live: <span className="text-green-500">{callStats.answered_live_calls}</span>/{callStats.live_calls}
                    </div>
                    <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                        Missed: {callStats.missed_calls}
                    </div>
                </div>
                <div className="relative mt-[3px] flex h-[61px] w-full flex-grow items-center justify-around gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
                    <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                        {`$ ${userDetails.balance}`}
                    </div>
                    <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white px-3">
                        <MdTimer className="mr-2" /> {cestTime.toLocaleTimeString('en-US', { timeZone: 'America/Chicago' })}
                    </div>
                    <span
                        className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
                        onClick={onOpenSidenav}
                    >
                        <FiAlignJustify className="h-5 w-5" />
                    </span>

                    <div
                        className="cursor-pointer text-gray-600"
                        onClick={() => {
                            if (darkmode) {
                                document.body.classList.remove("dark");
                                setDarkmode(false);
                            } else {
                                document.body.classList.add("dark");
                                setDarkmode(true);
                            }
                        }}
                    >
                        {darkmode ? (
                            <RiSunFill className="h-4 w-4 text-gray-600 dark:text-white" />
                        ) : (
                            <RiMoonFill className="h-4 w-4 text-gray-600 dark:text-white" />
                        )}
                    </div>
                    {/* Profile & Dropdown */}
                    <Dropdown
                        button={
                            <Avatar name={userDetails.full_name} className="h-10 w-10 cursor-pointer" src='https://bit.ly/broken-link' />
                        }
                        children={
                            <div className="flex w-56 flex-col justify-start rounded-[20px] bg-white bg-cover bg-no-repeat shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
                                <div className="p-4">
                                    <div className="flex items-center gap-2">
                                        <p className="text-sm font-bold text-navy-700 dark:text-white">
                                            👋 Hey, {userDetails.full_name}
                                        </p>{" "}
                                    </div>
                                </div>
                                <div className="h-px w-full bg-gray-200 dark:bg-white/20 " />

                                <div className="flex flex-col p-4">
                                    <div
                                        className="w-full mb-3 cursor-pointer text-sm text-gray-800 dark:text-white hover:dark:text-white"
                                        onClick={() => setModalOpen(true)}>
                                        Change Password
                                    </div>
                                    {/* <a
                                    href=" "
                                    className="mt-3 text-sm text-gray-800 dark:text-white hover:dark:text-white"
                                >
                                    Newsletter Settings
                                </a> */}
                                    <span
                                        onClick={handleLogout}
                                        className="w-full text-sm font-medium text-red-500 hover:text-red-500 transition duration-150 ease-out hover:ease-in cursor-pointer"
                                    >
                                        Log Out
                                    </span>
                                </div>
                            </div>
                        }
                        classNames={"py-2 top-8 -left-[180px] w-max"}
                    />
                </div>
            </nav>
            <GenericModalForm
                isOpen={isModalOpen}
                onClose={() => setModalOpen(false)}
                title="Change Password"
                formFields={formFields}
                // initialValues={initialValues} // Pass initialValues only if editing
                onSubmit={handleSubmit}
            />
        </>
    );
};

export default Navbar;
